export const setClasses = (
  ...classes: Array<
    string | undefined | { [className: string]: boolean | undefined }
  >
): string => {
  return classes
    .map(
      (
        item: string | undefined | { [className: string]: boolean | undefined }
      ) => {
        if (typeof item === "string") {
          return item;
        }
        if (typeof item === "object") {
          const key: string = Object.keys(item)[0];
          if (item[key] === true) {
            return key;
          }
        }
        return null;
      }
    )
    .filter((item: string | null) => item !== null)
    .join(" ");
};
