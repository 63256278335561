import React, { useState, useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { simpleAjaxRequest } from "../helpers/simple-ajax-request";
import { setClasses } from "../helpers/set-classes";

import sendMailIcon from "../images/send-mail.svg";
import successMailIcon from "../images/success-mail.svg";
import sendIcon from "../images/icons/send.svg";
import profileIcon from "../images/icons/profile.svg";
import emailIcon from "../images/icons/email.svg";

type SubmissionStatus = "INITIAL" | "LOADING" | "SUCCESS" | "ERROR";

const removeProperty = (obj: any, propertyName: string): any => {
  const { [propertyName]: _, ...result } = obj;
  return result;
};

const InputErrors = ({
  errors,
}: {
  errors: string[] | string | undefined;
}): JSX.Element | null => {
  if (!errors || errors.length === 0) {
    return null;
  }
  if (typeof errors === "string") {
    errors = [errors];
  }
  return <aside className="input-errors">{errors.join(",")}</aside>;
};

const ContactMePage = (): JSX.Element => {
  const [status, setStatus] = useState<SubmissionStatus>("INITIAL");
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [browserMeta, setBrowserMeta] = useState<{ ip: string }>({
    ip: "IP Unknown",
  });
  let timer: any = null;
  useEffect(() => {
    // componentDidMount
    async function fetchInformation() {
      const response = await simpleAjaxRequest(
        "GET",
        "https://api.ipify.org/?format=json"
      );
      setBrowserMeta(response);
    }
    fetchInformation();
    return (): void => {
      clearTimeout(timer);
    };
  }, []);
  const validateForm = (data: FormData): { [key: string]: string[] } => {
    let errors: { [key: string]: string[] } = {};
    const validators: any = {
      name: (value: string) => {
        if (value.length <= 1) {
          return { name: ["Name is too short"] };
        }
        return {};
      },
      email: (value: string) => {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          return { email: ["Is not a valid email address"] };
        }
        return {};
      },
      message: (value: string) => {
        if (value.length <= 5) {
          return { message: ["Message is too short"] };
        }
        return {};
      },
      _gotcha: (value: string) => {
        if (value.length >= 1) {
          return { _gotcha: ["Are you sure you're not a robot?"] };
        }
        return {};
      },
      _subject: (value: string) => ({}),
    };
    for (const [key, value] of data.entries()) {
      errors = {
        ...errors,
        ...(validators[key] ? validators[key](value) : {}),
      };
    }
    return errors;
  };
  const submitForm = async (
    ev: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    ev.preventDefault();
    setStatus("LOADING");
    const form = ev.target as HTMLFormElement;
    const data = new FormData(form);
    const errors = validateForm(data);
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      // setStatus("ERROR");
      timer = setTimeout(() => {
        setStatus("ERROR");
      }, 800);
      return;
    }
    const formValues = {
      name: data.get("name"),
      email: data.get("email"),
      message: data.get("message"),
      _subject: data.get("_subject"),
    };
    data.set("_subject", formValues._subject + " " + formValues.name);
    data.set(
      "message",
      formValues.message +
        "\n\n---\n" +
        navigator.userAgent +
        "\n" +
        browserMeta.ip
    );
    try {
      const response = await simpleAjaxRequest(
        form.method,
        process.env.GATSBY_CONTACT_FORM_URL as string,
        data
      );
      // form.reset();
      timer = setTimeout(() => {
        setStatus("SUCCESS");
      }, 1200);
      // setStatus("SUCCESS");
    } catch (err) {
      setStatus("ERROR");
      setErrors({ other: [err.error] });
    }
  };
  const onKeyPress = (event: any): void => {
    const { name } = event.currentTarget;
    setErrors(removeProperty(errors, name));
  };
  return (
    <Layout slug="contact-me">
      <SEO title="Contact me" />
      {status !== "SUCCESS" ? (
        <div id="contact" style={{ zIndex: 1 }}>
          <aside className="description">
            <h2>Get in touch</h2>
            <p>
              I’d love to <br />
              hear from you
            </p>
            <div className="sendMailIcon">
              <img src={sendMailIcon} alt="" />
            </div>
          </aside>
          <form
            className="form"
            onSubmit={submitForm}
            action={"/javascript-needs-to-be-enabled"}
            method="POST"
          >
            <input
              name="_gotcha"
              tabIndex={-1}
              style={{
                outline: "none",
                height: "1px",
                padding: 0,
                margin: 0,
                border: 0,
                width: "1px",
                background: "black",
                position: "absolute",
                top: 0,
                opacity: 0.1,
              }}
              type="text"
              aria-hidden="true"
            />
            <InputErrors errors={errors["_gotcha"]} />
            <input type="hidden" name="_subject" value="(Auto DW)" />
            <div className="form-row">
              <div
                className={setClasses("form-field", {
                  "has-error": !!errors["name"],
                })}
              >
                <div className="icon-input right">
                  <input
                    className="input"
                    name="name"
                    placeholder="Your name"
                    type="text"
                    onKeyPress={onKeyPress}
                  />
                  <img src={profileIcon} alt="" />
                </div>
                <InputErrors errors={errors["name"]} />
              </div>
            </div>
            <div className="form-row">
              <div
                className={setClasses("form-field", {
                  "has-error": !!errors["email"],
                })}
              >
                <div className="icon-input right">
                  <input
                    className="input"
                    name="email"
                    placeholder="Your email"
                    type="text"
                    onKeyPress={onKeyPress}
                  />
                  <img src={emailIcon} alt="" />
                </div>
                <InputErrors errors={errors["email"]} />
              </div>
            </div>
            <div className="form-row">
              <div
                className={setClasses("form-field", {
                  "has-error": !!errors["message"],
                })}
              >
                <textarea
                  className="input"
                  style={{ minHeight: "24vh" }}
                  name="message"
                  placeholder="How can I help?"
                  onKeyPress={onKeyPress}
                ></textarea>
                <InputErrors errors={errors["message"]} />
              </div>
            </div>
            <div className="submit-holder">
              <button
                className={setClasses("btn blue rounded xl", {
                  loading: status === "LOADING",
                })}
                type="submit"
                disabled={status === "LOADING"}
              >
                <img src={sendIcon} alt="Send" />
              </button>
            </div>
            {/* <span style={{ position: "absolute" }}>{status}</span> */}
          </form>
        </div>
      ) : (
        <div id="contact" className="success">
          <aside>
            <div className="successMailIcon">
              <img src={successMailIcon} alt="" />
            </div>
          </aside>
          <aside className="success-message">
            <h2>Thank you!</h2>
            <p>Your message has been received.</p>
          </aside>
        </div>
      )}
    </Layout>
  );
};

export default ContactMePage;
