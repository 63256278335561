export const simpleAjaxRequest = async (
  method: string,
  url: string,
  data?: any
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = (event: any) => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        try {
          resolve(JSON.parse(xhr.response));
        } catch (_err) {
          resolve(xhr.response);
        }
      } else {
        reject(
          event.currentTarget.response === ""
            ? {}
            : JSON.parse(event.currentTarget.response)
        );
      }
    };
    data ? xhr.send(data) : xhr.send();
  });
};
